import { axiosInstance } from "api";

export const sendMagicLink = async (email: string, redirectUrl: string) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/metashop/magic-link`, {
        email,
        redirectUrl,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
