import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { CookiesProvider, useCookies } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { setAuthorizationHeader } from 'api';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { configureAmplify } from 'utils/aws';
import UserVerification from 'components/auth/UserVerification';
import CognitoAuth from 'components/auth/CognitoAuth';
import { GlobalStyle } from '@styles/styled/GlobalStyle';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
	const [cookies, setCookie] = useCookies(['jwt', 'profileType']);
	const router = useRouter();

	useEffect(() => {
		configureAmplify();
	}, []);

	// when the router fields are updated client-side and ready for use
	useEffect(() => {
		if (router.isReady) {
			if (router.query.profileType) {
				setCookie("profileType", router.query.profileType, {
				  path: "/redemption",
				});
			  }
			if (router.query.token) {
				setCookie('jwt', router.query.token, { path: '/' });
				setAuthorizationHeader(router.query.token as string);
				router.replace(router.basePath);
			} else if (cookies['jwt']) {
				setAuthorizationHeader(cookies['jwt']);
			}
		}
	}, [router.isReady]);

	return (
		<CookiesProvider>
			<QueryClientProvider client={queryClient}>
				<UserVerification>
					<CognitoAuth>
						<GlobalStyle />
						<Component {...pageProps} />
					</CognitoAuth>
				</UserVerification>

				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</CookiesProvider>
	);
}

export default MyApp;
