export const pageLinks = {
  Home: {
    url: "/",
  },
  // Auth
  Auth: {
    url: "/auth",
  },
  Login: {
    url: "/auth/login",
  },
  SignUp: {
    url: "/auth",
  },
  ManiFitting: {
    url: "/fitting/mani",
  },
  PediFitting: {
    url: "/fitting/pedi",
  },
  Privacy: {
    url: "/privacy",
  },
  Terms: {
    url: "/terms",
  },
  Profile: {
    url: "/profile",
  },
  ManiRefit: {
    url: "/refitting?profileType=Manis",
  },
  PediRefit: {
    url: "/refitting?profileType=Pedis",
  },
};

export const publicLinks = [pageLinks.Home.url, pageLinks.Login.url];
