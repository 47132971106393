import { pageLinks } from "utils/links";
import { PROFILE_TYPES } from "./fittingFlow";

export const productionUrls = [
  "https://manime.co",
  "https://www.manime.co",
  "https://blue.manime.co",
  "https://green.manime.co",
  "https://beta1.manime.co",
];
export function isProduction() {
  return !process.env.APP_URL || productionUrls.includes(process.env.APP_URL);
}
export const referral = {
  NORMAL_REFERRER_CREDIT: 15,
  NORMAL_REFERREE_CREDIT: 15,
};
export const NORMAL_RATE_CREDIT = 5;
export const totalSteps = 5;
export const messages = {
  USER_NOT_EXIST: "User does not exist",
  USER_ALREADY_EXIST: "User already exists",
  INCORRECT_USERNAME_PASSWORD: "Incorrect username or password",
  VALIDATION_PASSWORD: "Please enter a password at least 6 characters long",
  INVALID_PHONE: "Invalid phone number format",
  INVALID_PHONE_FORMAT:
    "Invalid phone number format. Please use a phone number format of +12345678900",
  INVALID_PASSWORD_LENGTH: "Password must be at least 6 characters. Try again.",
};
export const fingers = [
  "left pinky finger",
  "left ring finger",
  "left middle finger",
  "left pointer finger",
  "left thumb",
  "right thumb",
  "right pointer finger",
  "right middle finger",
  "right ring finger",
  "right pinky finger",
];

export const fingerNames = [
  "LEFT LITTLE FINGER",
  "LEFT RING FINGER",
  "LEFT MIDDLE FINGER",
  "LEFT INDEX FINGER",
  "LEFT THUMB FINGER",
  "RIGHT THUMB FINGER",
  "RIGHT INDEX FINGER",
  "RIGHT MIDDLE FINGER",
  "RIGHT RING FINGER",
  "RIGHT LITTLE FINGER",
];

export const toeNames = [
  "LEFT LITTLE TOE",
  "LEFT RING TOE",
  "LEFT MIDDLE TOE",
  "LEFT SECOND TOE",
  "LEFT BIG TOE",
  "RIGHT BIG TOE",
  "RIGHT SECOND TOE",
  "RIGHT MIDDLE TOE",
  "RIGHT RING TOE",
  "RIGHT LITTLE TOE",
];

export const EMPTY_PRODUCT_TITLE = "DON'T DELETE - not visible";
export const WELCOME_CARD_TRIFOLD_TITLE = "Welcome Card Trifold";
export const FREE_MAGIC_CUTICLE_PEN = "Gift Magic Cuticle Pen";
export const FREE_PINK_MINI_TOTE_BAG = "Pink Mini Tote Bag";
export const FREE_MIRELLA_TOP_COAT = "Gift Mirella Top Coat";
export const FREE_MAX_TOP_COAT = "Gift Max Top Coat";
export const WELCOME_CARD_TRIFOLD = "Welcome Card Trifold";
export const WELCOME_CARD = "Welcome Card";
export const EMPTY_PRODUCT = "DON'T DELETE - not visible";
export const notEditableInCart = [
  "Gift Mirella Top Coat",
  "Gift Mon Ami Clipper",
];

export const QUERIES = {
  LOAD_CURRENT_USER: "LOAD_CURRENT_USER",
  LOAD_CURRENT_USER_PROFILES: "LOAD_CURRENT_USER_PROFILES",
  COGNITO_CURRENT_AUTHENTICATED_USER: "COGNITO_CURRENT_AUTHENTICATED_USER",
};

export const SEGMENT_EVENTS = {
  SEND_SMS: "[desktopTransfer][sendSMS]",
  PASSWORDLESS_SIGN_IN: "[passwordlessSignIn] cognito login successful",
  IDENTITY_ID_NOT_EQUAL_USER_ID:
    "[passwordlessSignIn] identityid is not equal to userid",
  MANUAL_LOGIN: "[signIn] cognito login successful",
  FIT_REVIEW_CREATED: "profilechange-fitReviewCreated",
  FIT_REVIEW_STARTED: "fitReviewStarted",
  REFIT_STARTED: "[refit][start]",
  REFIT_SUBMITTED: "Refit Submitted",
  REFIT_SUBMITTED_CLICKED: "[refit][onSubmit] clicked submit",
  REFIT_DROP: "[review][onDrop] dropped image",
};

export const FIT_CARDS_INFO = {
  MANI_FIT: {
    title: "MANI PROFILE",
    profileType: PROFILE_TYPES.MANI,
    instructionTitle: "SCAN YOUR NAILS",
    instruction: (
      <>
        Your nail size is unique. Scan your mani and we <br /> will custom size
        your stick on gels.
      </>
    ),

    scanButtonLabel: "Start Scan",
    nextPath: pageLinks.ManiFitting.url,
  },
  PEDI_FIT: {
    title: "PEDI PROFILE",
    profileType: PROFILE_TYPES.PEDI,
    instructionTitle: "SCAN YOUR TOENAILS",
    instruction: (
      <>
        Your toenail size is unique. Scan your pedi and <br /> we will custom
        size your stick on gels.
      </>
    ),
    scanButtonLabel: "Start Scan",
    nextPath: pageLinks.PediFitting.url,
  },
  MANI_FIT_LAB: {
    title: "MANI FIT LAB",
    profileType: PROFILE_TYPES.MANI_FIT_LAB,
    instructionTitle: "REFIT YOUR MANIS",
    instruction: (
      <>
        Not 100% satisfied with your mani fit? Don't worry! <br />
        Start refitting here with notes +  some photos wearing your set and we'll update your current custom fit!
      </>
    ),
    scanButtonLabel: "Start Refitting",
    nextPath: pageLinks.ManiRefit.url,
  },
  PEDI_FIT_LAB: {
    title: "PEDI FIT LAB",
    profileType: PROFILE_TYPES.PEDI_FIT_LAB,
    instructionTitle: "REFIT YOUR PEDIS",
    instruction: (
      <>
        Not 100% satisfied with your pedi fit? Don't worry! <br />
        Start refitting here with notes +  some photos wearing your set and we'll update your current custom fit!
      </>
    ),

    scanButtonLabel: "Start Refitting",
    nextPath: pageLinks.PediRefit.url,
  },
};

export const INITIAL_OFFSETS = {
  left: {
    index: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
    middle: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
    pinky: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
    ring: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
    thumb: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
  },
  right: {
    index: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
    middle: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
    pinky: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
    ring: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
    thumb: {
      tipWidth: 0,
      tipLength: 0,
      baseWidth: 0,
      cuticleShape: 0,
      overallWidth: 0,
    },
  },
};

export const INITIAL_COMMENTS = {
  left: {
    thumb: null,
    index: null,
    middle: null,
    ring: null,
    pinky: null
  },
  right: {
    thumb: null,
    index: null,
    middle: null,
    ring: null,
    pinky: null
  },
}

const INITIAL_FINGER_LIMITS = {
  pinky: 0,
  ring: 0,
  middle: 0,
  index: 0,
  thumb: 0,
};

const INITIAL_LIMITS = {
  min: {
    left: INITIAL_FINGER_LIMITS,
    right: INITIAL_FINGER_LIMITS,
  },
  max: {
    left: INITIAL_FINGER_LIMITS.index,
    right: INITIAL_FINGER_LIMITS,
  },
};

export const INITIAL_SLIDERS = {
  tipLength: {
    limits: INITIAL_LIMITS,
    startingValues: {
      left: INITIAL_FINGER_LIMITS,
      right: INITIAL_FINGER_LIMITS,
    },
  },
  overallWidth: {
    limits: INITIAL_LIMITS,
    startingValues: {
      left: INITIAL_FINGER_LIMITS,
      right: INITIAL_FINGER_LIMITS,
    },
  },
  cuticleShape: {
    limits: INITIAL_LIMITS,
    startingValues: {
      left: INITIAL_FINGER_LIMITS,
      right: INITIAL_FINGER_LIMITS,
    },
  },
  tipWidth: {
    limits: INITIAL_LIMITS,
    startingValues: {
      left: INITIAL_FINGER_LIMITS,
      right: INITIAL_FINGER_LIMITS,
    },
  },
  baseWidth: {
    limits: INITIAL_LIMITS,
    startingValues: {
      left: INITIAL_FINGER_LIMITS,
      right: INITIAL_FINGER_LIMITS,
    },
  },
};

export const ACCURACY = 1000;
export const SLIDER_STEPS = 10;
