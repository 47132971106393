import React from "react";
import { useCurrentUser } from "hooks/userHooks";
import LoadingAnimation from "../LoadingAnimation";
import ExpirationBanner from "components/redemption/ExpirationBanner";
import { publicLinks } from "utils/links";
import { useRouter } from "next/router";

export default function UserVerification({ children }) {
  const { data: currentUser, isLoading: currentUserLoading } = useCurrentUser();
  const router = useRouter();

  if (currentUserLoading) {
    return <LoadingAnimation />;
  }
  if (currentUser || publicLinks.includes(router.asPath)) {
    return children;
  } else {
    return <ExpirationBanner />;
  }
}
