import React, { useState } from "react";

import styles from "@styles/gift/group/confirmation.module.css";
import ReactMarkdown from "react-markdown";
import Box from "components/styled/Box";
import {
  Img,
  StandardInput,
  StyledStandardDarkButton,
} from "components/styled/StyledComponents";
import { useSendMagicLink } from "hooks/redemptionHooks";

export default function RedemptionExpirationBanner() {
  const [email, setEmail] = useState("");
  const {
    mutate: sendMagicLink,
    isError,
    isLoading,
    isIdle,
    isSuccess,
  } = useSendMagicLink();
  const copy1 = `Your magic link to get custom-fit is no longer valid. You’ll need a new one.`;
  const copy2 = `If you placed an order, you should have  
  received an email confirmation with a valid link.  

  Type your email below to resend a valid link.

  Reach out to care@manime.co with any questions. `;

  const errorMessage = `Invalid Email Address.
  Sign up now to be able to access the fitting flow.

  Reach out to care@manime.co with any questions.
  `;
  const confirmationMessage = `Sent email with a valid redemption link.`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={`${styles.confirmBannerContainer}`}>
        <div className={styles.orderConfirmTitle}>Your Custom-Fit Scan</div>

        <div className={styles.redeemErrorMessage}>
          <ReactMarkdown>{copy1}</ReactMarkdown>
        </div>
        <Img
          className={styles.groupGiftImage}
          src='https://cdn.shopifycdn.net/s/files/1/0253/6561/0605/files/group-gift-banner_70e30a30-d095-4627-996a-a372c51bb728.png?v=1618068596'
          alt='group-gift'
        />
      </div>
      <div
        className={`${styles.redeemErrorMessage} ${styles.bottomErrorMessage}`}
      >
        <ReactMarkdown>{copy2}</ReactMarkdown>
      </div>
      <Box flex='1 0 auto' flexDirection='column' width={1} maxWidth='320px'>
        <form>
          <StandardInput
            type='email'
            name='email'
            placeholder='Email Address'
            borderRadius='6px'
            value={email || ""}
            onChange={(event) => setEmail(event.target.value)}
          />

          <StyledStandardDarkButton
            width='100%'
            height='40px'
            onClick={(event) => {
              event.preventDefault();
              sendMagicLink(email);
            }}
            style={{ textTransform: "uppercase" }}
            disabled={isSuccess}
          >
            Resend
          </StyledStandardDarkButton>
        </form>
      </Box>
      {!isLoading && !isIdle && (
        <div
          className={`${styles.redeemErrorMessage} ${styles.bottomErrorMessage}`}
        >
          <ReactMarkdown>
            {isError ? errorMessage : confirmationMessage}
          </ReactMarkdown>
        </div>
      )}
    </div>
  );
}
