import { axiosInstance } from ".";

export const getCurrentUser = async () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get("/metashop/users/current")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const setUserIdByIdentityId = async (userIdentityId: string) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch("/metashop/users/current/cognito-identity-id", {
        cognitoIdentityId: userIdentityId,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const authenticateCognitoUser = async (idToken: string) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("/metashop/cognito/login", {
        id_token: idToken,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
