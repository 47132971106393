import Amplify from "@aws-amplify/core";
import config from "../config";

export function configureAmplify() {
  let identityPoolId = config.aws.amplify.identityPoolId;
  let region = config.aws.amplify.region;
  let userPoolId = config.aws.amplify.userPoolId;
  let userPoolWebClientId = config.aws.amplify.userPoolWebClientId;
  const awsConfig = {
    Auth: {
      identityPoolId,
      region,
      userPoolId,
      userPoolWebClientId,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_SRP_AUTH' | "CUSTOM_AUTH",
      federationTarget: "COGNITO_USER_POOLS",
    },
    Storage: {
      bucket: config.aws.storage.bucket,
      region: config.aws.storage.region,
    },
  };
  Amplify.configure(awsConfig);
}
