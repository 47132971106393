import { QUERIES } from "../constants";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCurrentUser, setUserIdByIdentityId } from "api/auth";
import { createEmptyProfile, getUserProfiles } from "api/profile";
import { updateProfile } from "api/profile";
import { setAuthorizationHeader } from "api";
import { useCookies } from "react-cookie";
import { Auth } from "@aws-amplify/auth";
import { isMatchingIdentityIdFormat } from "utils/authUtils";
import { useWriteLog } from "./loggingHooks";

export const useCurrentUser = () => {
  return useQuery(QUERIES.LOAD_CURRENT_USER, getCurrentUser, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
    onSuccess: (user: any) => {
      analytics.identify(user.email, {
        email: user.email,
      });
    },
  });
};

export const userCurrentUserProfiles = () => {
  return useQuery(QUERIES.LOAD_CURRENT_USER_PROFILES, getUserProfiles);
};

export const useEditProfile = () => {
  const queryClient = useQueryClient();
  return useMutation((profile: any) => updateProfile(profile, {}), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.LOAD_CURRENT_USER_PROFILES);
    },
    onMutate: async (profile) => {
      await queryClient.cancelQueries(QUERIES.LOAD_CURRENT_USER_PROFILES);
      const previousProfiles = queryClient.getQueryData(
        QUERIES.LOAD_CURRENT_USER_PROFILES
      );

      // Optimistically update to the new value
      queryClient.setQueryData(
        QUERIES.LOAD_CURRENT_USER_PROFILES,
        (oldProfiles: any) => {
          let newProfiles = oldProfiles.map((p: any) => {
            if (p.id === profile.id) {
              return { ...profile, ...p };
            }
            return p;
          });
          return newProfiles;
        }
      );

      return { previousProfiles };
    },
  });
};

export const useCreateEmptyProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (profileType: "Pedis" | "Manis") => createEmptyProfile(profileType),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES.LOAD_CURRENT_USER_PROFILES);
      },
    }
  );
};

/* 
  Update the user id by Cognito identity id in his first sign in
*/
export const useUpdateUserIdByIdentityId = (
  userEmail: string,
  userId: string
) => {
  const queryClient = useQueryClient();
  const [, setCookie] = useCookies(["jwt"]);
  const { mutate: logError } = useWriteLog(userEmail, userId, "error");
  const { mutate: logInfo } = useWriteLog(userEmail, userId, "info");

  return useMutation(
    (userIdentityId: string) => setUserIdByIdentityId(userIdentityId),
    {
      // optimistically update userId in the cache
      onMutate: (identityId: string) => {
        const previousUser = queryClient.getQueryData(
          QUERIES.LOAD_CURRENT_USER
        );
        queryClient.setQueryData(QUERIES.LOAD_CURRENT_USER, (user: any) => ({
          ...user,
          userId: identityId,
        }));
        return { previousUser };
      },
      onSuccess: async (data: any) => {
        queryClient.setQueryData(QUERIES.LOAD_CURRENT_USER, data.user);
        setCookie("jwt", data.token, { path: "/" });
        setAuthorizationHeader(data.token);

        const authenticatedUser = await Auth.currentAuthenticatedUser();
        if (isMatchingIdentityIdFormat(data.user.userId) && authenticatedUser) {
          await Auth.updateUserAttributes(authenticatedUser, {
            "custom:identity_id": data.user.userId,
          });
        }
        logInfo({
          logDescription: "Updating userId to identityId in first login",
          context: {
            newUserId: data.user.userId,
          },
        });
      },
      // rollback upon failure
      onError: (err, identityId, context: any) => {
        queryClient.setQueryData(
          QUERIES.LOAD_CURRENT_USER,
          context.previousUser
        );
        logError({
          logDescription: "Error updating userId in first login",
          context: {
            identityId,
            error: err,
          },
        });
      },
    }
  );
};
