import { axiosInstance } from "api";

export const writeLog = async (message: any) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("/write-log", {
        appName: window.location.host,
        env: process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV,
        msg: JSON.stringify(message),
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
