import { sendMagicLink } from "api/redemption";
import { useMutation } from "react-query";
import { pageLinks } from "utils/links";

export const useSendMagicLink = () => {
  return useMutation((email: string) =>
    sendMagicLink(
      email,
      `${process.env.NEXT_PUBLIC_APP_URL}${pageLinks.Profile.url}`
    )
  );
};
