import { Auth } from "@aws-amplify/auth";

export const passwordlessSignIn = async (
  userName: string,
  challengeToken: string
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await Auth.signIn(userName);
      if (
        user.challengeName === "CUSTOM_CHALLENGE" &&
        user.challengeParam.distraction === "Yes"
      ) {
        await Auth.sendCustomChallengeAnswer(user, challengeToken);
      }
      const credentials = await Auth.currentCredentials();
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      resolve({ authenticatedUser, credentials });
    } catch (err) {
      reject(err);
    }
  });
};

export const isMatchingIdentityIdFormat = (identityId: string) => {
  return identityId.startsWith("us-west-2:");
};
