import { writeLog } from "api/logging";
import { useMutation } from "react-query";

export const useWriteLog = (
  userEmail: string,
  userId: string,
  level: "info" | "error" = "info"
) => {
  return useMutation((variables: any) => {
    const message = {
      userId,
      userEmail,
      level,
      ...variables,
    };
    return writeLog(message);
  });
};
