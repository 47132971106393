const config = {
  env: process.env.ENV,

  aws: {
    amplify: {
      identityPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_IDENDITY_POOL_ID,
      region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION,
      userPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID,
      userPoolWebClientId:
        process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
      domain: process.env.NEXT_PUBLIC_AWS_AMPLIFY_DOMAIN,
    },
    storage: {
      bucket: process.env.NEXT_PUBLIC_AWS_S3_BUCKET,
      region: process.env.NEXT_PUBLIC_AWS_S3_REGION,
    },
  },
};
export default config;
