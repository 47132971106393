import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/AvenirLTStd-Black.otf');
    src: url('/fonts/AvenirLTStd-Book.otf');
    src: url('/fonts/AvenirLTStd-Heavy.otf');
    src: url('/fonts/AvenirLTStd-Light.otf');
    src: url('/fonts/AvenirLTStd-Medium.otf');
    font-display: swap;
  }
  @font-face {
    font-family: 'avenirBook';
    src: url('/fonts/AvenirLTStd-Book.otf');
    font-weight: 600;
    font-display: swap;
  }  
  @font-face {
    font-family: 'avenirHeavy';
    src: url('/fonts/AvenirLTStd-Heavy.otf');
    font-weight: 700;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'avenirLight';
    src: url('/fonts/AvenirLTStd-Light.otf');
    font-weight: 400;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'avenirMedium';
    src: url('/fonts/AvenirLTStd-Medium.otf');
    font-weight: 400;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'avenirBlack';
    src: url('/fonts/AvenirLTStd-Black.otf');
    font-weight: 600;
    font-display: swap;
  }
`;
