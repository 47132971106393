// Manis Fitting Flow
/**
 * Must follow the following order for placing fingers in objects
 * 1. Left Fingers
 * 2. Left Thumb
 * 3. Right Fingers
 * 4. Right Thumb
 * 5. Side
 */
export const MANIS_FITTING_IMAGES_KEY_MAP = {
  leftFingers: {
    camelCase: "leftFingers",
    statusCamel: "statusLeftFingers",
    versionLower: "versionleftfingers",
    versionCamel: "versionLeftFingers",
    index: 0,
  },
  leftThumb: {
    camelCase: "leftThumb",
    statusCamel: "statusLeftThumb",
    versionLower: "versionleftthumb",
    versionCamel: "versionLeftThumb",
    index: 1,
  },
  rightFingers: {
    camelCase: "rightFingers",
    statusCamel: "statusRightFingers",
    versionLower: "versionrightfingers",
    versionCamel: "versionRightFingers",
    index: 2,
  },
  rightThumb: {
    camelCase: "rightThumb",
    statusCamel: "statusRightThumb",
    versionLower: "versionrightthumb",
    versionCamel: "versionRightThumb",
    index: 3,
  },
  side: {
    camelCase: "side",
    statusCamel: "statusSide",
    versionLower: "versionside",
    versionCamel: "versionSide",
    index: 4,
  },
};

export const FITTING_MANIS_INSTRUCTIONS = [
  "Hold phone parallel to your hand",
  "Ensure entire card is in frame",
];

export const FITTING_MANIS_CONFIRMATION_QUESTIONS = [
  "Is the entire card in frame?",
  "Did you hold your phone above your hand?",
];

export const MANIS_FINGER_INFO = [
  {
    title: "4 LEFT FINGERS",
    description: "Take a picture of your left 4 fingers.",
    instructions: FITTING_MANIS_INSTRUCTIONS,
    confirmationQuestions: FITTING_MANIS_CONFIRMATION_QUESTIONS,
    imgSrc:
      "https://d1b527uqd0dzcu.cloudfront.net/web/gif/left_four_fingers.gif",
    videoSrc:
      "https://d1b527uqd0dzcu.cloudfront.net/web/videos/left_four_fingers.mp4",
    imgFrame1: "/static/images/left-finger.jpg",
    imgFrame2: "/static/images/left-finger-2.jpg",
    fileName: "leftFingers",
    statusCamel: "statusLeftFingers",
  },
  {
    title: "LEFT THUMB",
    description: "Now, take a picture of your left thumb.",
    instructions: FITTING_MANIS_INSTRUCTIONS,
    confirmationQuestions: FITTING_MANIS_CONFIRMATION_QUESTIONS,
    imgSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/gif/left_thumb.gif",
    videoSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/videos/left_thumb.mp4",
    imgFrame1: "/static/images/left-thumb.jpg",
    imgFrame2: "/static/images/left-thumb-2.jpg",
    fileName: "leftThumb",
    statusCamel: "statusLeftThumb",
  },
  {
    title: "4 RIGHT FINGERS",
    description: "Take a picture of your 4 right fingers.",
    instructions: FITTING_MANIS_INSTRUCTIONS,
    confirmationQuestions: FITTING_MANIS_CONFIRMATION_QUESTIONS,
    imgSrc:
      "https://d1b527uqd0dzcu.cloudfront.net/web/gif/right_four_fingers.gif",
    videoSrc:
      "https://d1b527uqd0dzcu.cloudfront.net/web/videos/right_four_fingers.mp4",
    imgFrame1: "/static/images/right-finger.jpg",
    imgFrame2: "/static/images/right-finger-2.jpg",
    fileName: "rightFingers",
    statusCamel: "statusRightFingers",
  },
  {
    title: "RIGHT THUMB",
    description: "Let's not forget your right thumb!",
    instructions: FITTING_MANIS_INSTRUCTIONS,
    confirmationQuestions: FITTING_MANIS_CONFIRMATION_QUESTIONS,
    imgSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/gif/right_thumb.gif",
    videoSrc:
      "https://d1b527uqd0dzcu.cloudfront.net/web/videos/right_thumb.mp4",
    imgFrame1: "/static/images/right-thumb.jpg",
    imgFrame2: "/static/images/right-thumb-2.jpg",
    fileName: "rightThumb",
    statusCamel: "statusRightThumb",
  },
  {
    title: "FRONT VIEW",
    description:
      "Last but not least, we want to scan the curvature of your nails!",
    instructions: ["Use either hand to take a front view picture"],
    confirmationQuestions: ["Is the curvature of your nails visible?"],
    imgSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/gif/side_view.gif",
    videoSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/videos/side.mp4",
    imgFrame1: "/static/images/side-card.jpg",
    imgFrame2: "/static/images/side-card.jpg",
    fileName: "side",
    statusCamel: "statusSide",
  },
];

// Pedis Fitting Flow
/**
 * Must follow the following order for placing fingers in objects
 * 1. Left Foot
 * 2. Left Foot
 * 3. Side Pinky
 * 4. Side
 */

export const PEDIS_FITTING_IMAGES_KEY_MAP = {
  leftFingers: {
    camelCase: "leftFingers",
    statusCamel: "statusLeftFingers",
    versionLower: "versionleftfingers",
    versionCamel: "versionLeftFingers",
    index: 0,
  },
  rightFingers: {
    camelCase: "rightFingers",
    statusCamel: "statusRightFingers",
    versionLower: "versionrightfingers",
    versionCamel: "versionRightFingers",
    index: 1,
  },
  sidePinky: {
    camelCase: "sidePinky",
    statusCamel: "statusSidePinky",
    versionLower: "versionsidepinky",
    versionCamel: "versionSidePinky",
    index: 2,
  },
  side: {
    camelCase: "side",
    statusCamel: "statusSide",
    versionLower: "versionside",
    versionCamel: "versionSide",
    index: 3,
  },
};

export const FITTING_PEDIS_INSTRUCTIONS = [
  "Hold phone parallel to your foot",
  "Ensure entire card is in frame",
];

export const FITTING_PEDIS_CONFIRMATION_QUESTIONS = [
  "Is the entire card in frame?",
  "Did you hold your phone above your foot?",
];

export const PEDIS_FINGER_INFO = [
  {
    title: "LEFT FOOT",
    description: "Take a picture of your left foot",
    instructions: FITTING_PEDIS_INSTRUCTIONS,
    confirmationQuestions: FITTING_PEDIS_CONFIRMATION_QUESTIONS,
    imgSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/pedi/left-foot1.jpg",
    // videoSrc: 'https://d1b527uqd0dzcu.cloudfront.net/web/videos/left_four_fingers.mp4',
    imgFrame1: "../../../static/images/left-finger.jpg",
    imgFrame2: "../../../static/images/left-finger-2.jpg",
    fileName: "leftFingers",
    statusCamel: "statusLeftFingers",
  },
  {
    title: "RIGHT FOOT",
    description: "Take a picture of your right foot.",
    instructions: FITTING_PEDIS_INSTRUCTIONS,
    confirmationQuestions: FITTING_PEDIS_CONFIRMATION_QUESTIONS,
    imgSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/pedi/right-foot1.jpg",
    // videoSrc: 'https://d1b527uqd0dzcu.cloudfront.net/web/videos/right_four_fingers.mp4',
    imgFrame1: "../../../static/images/right-finger.jpg",
    imgFrame2: "../../../static/images/right-finger-2.jpg",
    fileName: "rightFingers",
    statusCamel: "statusRightFingers",
  },
  {
    title: "RIGHT PINKY",
    description: "Let's not forget your right pinky!",
    instructions: [
      FITTING_PEDIS_INSTRUCTIONS[0],
      "Make sure you can see the whole nail",
    ],
    confirmationQuestions: [
      "Is the pinky toenail in frame?",
      "Did you hold your phone above your foot?",
    ],
    imgSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/pedi/right-pinky1.jpg",
    // videoSrc: 'https://d1b527uqd0dzcu.cloudfront.net/web/videos/side.mp4',
    imgFrame1: "../../../static/images/side-card.jpg",
    imgFrame2: "../../../static/images/side-card.jpg",
    fileName: "sidePinky",
    statusCamel: "statusSidePinky",
  },
  {
    title: "FRONT VIEW",
    description:
      "Last but not least, we want to scan the curvature of your toenails!",
    instructions: ["Use either foot to take a front view picture"],
    confirmationQuestions: ["Is the curvature of your toenails visible?"],
    imgSrc: "https://d1b527uqd0dzcu.cloudfront.net/web/pedi/front-view1.jpg",
    // videoSrc: 'https://d1b527uqd0dzcu.cloudfront.net/web/videos/side.mp4',
    imgFrame1: "../../../static/images/side-card.jpg",
    imgFrame2: "../../../static/images/side-card.jpg",
    fileName: "side",
    statusCamel: "statusSide",
  },
];

export const PROFILE_TYPES = {
  PEDI: "Pedis",
  MANI: "Manis",
  MANI_FIT_LAB: "Mani Fit Lab",
  PEDI_FIT_LAB: "Pedi Fit Lab",
};
