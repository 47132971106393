import { useMutation, useQuery, useQueryClient } from "react-query";
import { Auth } from "@aws-amplify/auth";
import {
  isMatchingIdentityIdFormat,
  passwordlessSignIn,
} from "utils/authUtils";
import { QUERIES, SEGMENT_EVENTS } from "../constants";
import { useUpdateUserIdByIdentityId } from "./userHooks";
import { authenticateCognitoUser } from "api/auth";
import { setAuthorizationHeader } from "api";
import { useCookies } from "react-cookie";
import { useWriteLog } from "./loggingHooks";
import { Storage } from "@aws-amplify/storage";

export const useCurrentAuthenticatedUser = (options?: any) => {
  return useQuery(
    QUERIES.COGNITO_CURRENT_AUTHENTICATED_USER,
    () => Auth.currentAuthenticatedUser(),
    {
      retry: false,
      // retryOnMount: false,
      // refetchOnWindowFocus: false,
      staleTime: 30 * 60 * 1000, // 30 minutes
      ...options,
    }
  );
};

export const usePasswordlessSignIn = (
  userEmail: string,
  userId: string,
  jwtToken: string
) => {
  const queryClient = useQueryClient();
  const updateUserId = useUpdateUserIdByIdentityId(userEmail, userId);
  const { mutate: logInfo } = useWriteLog(userEmail, userId, "info");
  const { mutate: logError } = useWriteLog(userEmail, userId, "error");

  return useMutation(() => passwordlessSignIn(userEmail, jwtToken), {
    onSuccess: async (data: any) => {
      await queryClient.cancelQueries(
        QUERIES.COGNITO_CURRENT_AUTHENTICATED_USER
      );
      // Optimistically update to the new value
      queryClient.setQueryData(
        QUERIES.COGNITO_CURRENT_AUTHENTICATED_USER,
        data.authenticatedUser
      );
      let firstLogin = false;
      // Update userId in the first sign in
      const identityId = data.credentials.identityId;
      if (!isMatchingIdentityIdFormat(userId)) {
        updateUserId.mutate(identityId);
        // create empty folder for the user with the first identity id
        await Storage.put("", null, {
          level: "private",
        });
        firstLogin = true;
      }
      const logParams = {
        email: data.authenticatedUser.attributes.email,
        authType: "passwordless",
        firstLogin,
        userId,
        identityId,
      };
      analytics.track(SEGMENT_EVENTS.PASSWORDLESS_SIGN_IN, logParams);
      if (!firstLogin && identityId !== userId) {
        analytics.track(
          SEGMENT_EVENTS.IDENTITY_ID_NOT_EQUAL_USER_ID,
          logParams
        );
        logError({
          logDescription: SEGMENT_EVENTS.IDENTITY_ID_NOT_EQUAL_USER_ID,
          context: { error: logParams },
        });
      }
      logInfo({
        logDescription: SEGMENT_EVENTS.PASSWORDLESS_SIGN_IN,
        context: logParams,
      });
    },
    onError: (err) => {
      logError({
        logDescription: "Cognito passwordless login failed",
        context: { error: err },
      });
    },
  });
};

export const useAuthenticateCognitoUser = () => {
  const queryClient = useQueryClient();
  const [, setCookie] = useCookies(["jwt"]);

  return useMutation((idToken: string) => authenticateCognitoUser(idToken), {
    onSuccess: async (data: any) => {
      await queryClient.cancelQueries(QUERIES.LOAD_CURRENT_USER);
      // update user in the cache
      queryClient.setQueryData(QUERIES.LOAD_CURRENT_USER, data.user);
      setCookie("jwt", data.token, { path: "/" });
      setAuthorizationHeader(data.token);
    },
  });
};
