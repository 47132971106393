import { axiosInstance } from "api";

export const getUserProfiles = async () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/metashop/profiles`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateProfile = async (profile: any, metaData: any) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/profiles/patch`, {
        ...profile,
        metaData,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const createEmptyProfile = async (profileType: "Pedis" | "Manis") => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/metashop/profiles/empty`, {
        profileType,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
