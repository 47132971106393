import React, { useEffect } from "react";
import ExpirationBanner from "components/redemption/ExpirationBanner";
import {
  useCurrentAuthenticatedUser,
  usePasswordlessSignIn,
} from "hooks/authHooks";
import LoadingAnimation from "components/LoadingAnimation";
import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import { publicLinks } from "utils/links";
import { QUERIES } from "../../constants";
import { useQueryClient } from "react-query";
import { isMatchingIdentityIdFormat } from "utils/authUtils";

export default function CognitoAuth({ children }) {
  const queryClient = useQueryClient();
  const currentUser: any = queryClient.getQueryData(QUERIES.LOAD_CURRENT_USER);
  const [cookies] = useCookies(["jwt"]);
  // perform passwordless sign in to Cognito users pool
  const passwordlessSignIn = usePasswordlessSignIn(
    currentUser?.email,
    currentUser?.userId,
    cookies["jwt"]
  );
  const { data: cognitoUser, isLoading: cognitoUserLoading } =
    useCurrentAuthenticatedUser({
      onError: () => passwordlessSignIn.mutate(),
      onSuccess: async (data: any) => {
        if (currentUser.email !== data.username) {
          passwordlessSignIn.mutate();
        }
      },
      enabled: !!currentUser,
    });
  const router = useRouter();
  useEffect(() => {
    if (currentUser && !isMatchingIdentityIdFormat(currentUser.userId)) {
      passwordlessSignIn.mutate();
    }
  }, [currentUser?.userId]);

  if (cognitoUserLoading || passwordlessSignIn.isLoading) {
    return <LoadingAnimation />;
  }
  if ((currentUser && cognitoUser) || publicLinks.includes(router.asPath)) {
    return children;
  } else {
    return <ExpirationBanner />;
  }
}
